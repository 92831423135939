import * as React from 'react';

import { Button, DialogActions, DialogContent, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Lottie from 'lottie-react';
import LocationNotFound from '../../lottie/location_not_found.json';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function UnavailableDialog({ open, handleClose, }) {
  return (
    <div>
      <Dialog
        maxWidth={'xs'}
        fullWidth={Boolean(true)}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogContent sx={{ textAlign: 'center' }}>
          <Lottie animationData={LocationNotFound} loop={Boolean(true)} style={{ height: 200 }} />
          <Typography variant="subtitle1">Standort nicht verfügbar </Typography>
          <Typography variant="body2">Leider ist dieser Standort derzeit nicht verfügbar</Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: 3 }}>
          <Button fullWidth variant="contained" onClick={handleClose}>
            Sicher
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
