import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { CssBaseline } from '@mui/material';
import { styled } from '@mui/material/styles';
//
import { Suspense, useContext, useState } from 'react';
import ResponsiveAppBar from '../components/navbar/Navbar';
import { BannerContext } from '../context/bannerProvider';
import LeftSideCart from './LeftSideCart';
// import Scrollbar from '../../components/scrollbar';

// ----------------------------------------------------------------------

export const APP_BAR_MOBILE = 6;
export const APP_BAR_DESKTOP = 12;

// const StyledRoot = styled('div')({
//   display: 'flex',
//   // height: '100vH',
//   // overflow: 'auto',
// });

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { hideBanner } = useContext(BannerContext);
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    // overflow: 'auto',
    // minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + (hideBanner ? 0 : 40),
    paddingBottom: theme.spacing(10),
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 360px)',
      paddingTop: APP_BAR_DESKTOP,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <>
      <ResponsiveAppBar />
      <CssBaseline />
      <Main className="bg-white">
        <Suspense
          fallback={
            <div className="fixed left-0 top-0 z-[99999] grid h-full w-full place-items-center bg-white">
              <img src="/assets/logo.svg" alt="Diazo" />
            </div>
          }>
          <Outlet />
        </Suspense>
      </Main>
      {pathname === '/menu' && <LeftSideCart openNav={open} onCloseNav={() => setOpen(false)} />}
    </>
  );
}
