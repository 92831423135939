import { styled } from '@mui/material/styles';
import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Iconify from '../iconify/Iconify';
import GoogleMaps from '../map/GoogleMap';
import { AddressContext } from '../../context/AddressProvider';
import { BannerContext } from '../../context/bannerProvider';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const StyledContent = styled('div')(() => ({
  height: '100vh',
  width: '100vw',
}));

export default function MapDialog({ open, handleClose, ...props }) {
  const { addressValue } = React.useContext(AddressContext);
  const { hideBanner } = React.useContext(BannerContext);
  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', paddingTop: !hideBanner ? 3 : 0 }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <Iconify icon={'octicon:x-24'} />
            </IconButton>{' '}
            <Typography variant="body1" textTransform={'capitalize'}>
              {addressValue ? 'Adresse ändern' : 'Adresse hinzufügen'}
            </Typography>
          </Toolbar>
        </AppBar>

        <StyledContent>
          <GoogleMaps showField={Boolean(true)} handleClose={handleClose} {...props} />
        </StyledContent>
      </Dialog>
    </div>
  );
}
