import React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { Container, DialogActions, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import Iconify from '../iconify/Iconify';
import useResponsive from '../../hooks/useResponsive';
import { BannerContext } from '../../context/bannerProvider';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  backgroundColor: 'white',
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}));

const DialogWrapper = ({ children, title, open, handleClose, maxWidth = 'sm', scrollType = 'body' }) => {
  const isMobile = useResponsive('down', 'md');
  const { hideBanner } = React.useContext(BannerContext);
  return (
    <div>
      <Dialog
        maxWidth={maxWidth}
        fullWidth={Boolean(true)}
        fullScreen={isMobile ? Boolean(true) : Boolean(false)}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll={scrollType}>
        <DialogActions sx={{ display: 'flex', justifyContent: 'start', px: 2, paddingTop: !hideBanner ? 3 : 0 }}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <Iconify icon={'teenyicons:left-outline'} />
          </IconButton>
          <Typography variant="h3">{title}</Typography>
        </DialogActions>

        <StyledRoot>
          <Container maxWidth={maxWidth}>
            <StyledContent>{children}</StyledContent>
          </Container>
        </StyledRoot>
      </Dialog>
    </div>
  );
};

export default DialogWrapper;
