import {
  Alert,
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import React, { useContext } from 'react';
import { CartContext } from '../../context/cartProvider';
import useResponsive from '../../hooks/useResponsive';
import Iconify from '../iconify/Iconify';
import ErrorBoundry from '../alerts/ErrorBoundary';
import SvgColor from '../svg-color/SvgColor';
import { addVouchesToUser, checkPromoCode } from '../../utils/firebase/FirebaseCommon';
import { UserContext } from '../../context/userProvider';
import Label from '../label/Label';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const VoucherDialog = ({ open, handleClose, ...props }) => {
  const isMobile = useResponsive('down', 'sm');
  const [coupon, setCoupon] = React.useState('');
  const [error, setError] = React.useState(false);
  const { userInfo, setUserInfo } = useContext(UserContext);
  const { setApplyCoupon, calculateTotalPrice } = useContext(CartContext);

  const handleAddCoupon = async () => {
    if (calculateTotalPrice() < 20) {
      setError(true);
      return;
    }
    const promoCode = coupon.startsWith('Diazo') ? coupon : null;
    if (promoCode) {
      if (userInfo.referredBy) {
        props.handleAlert({ open: true, message: 'You have already used a promo code!', type: 'error' });
        return;
      }
      const currentUserEmailOrPhone = userInfo.email || userInfo.phone;
      const promoCheckResult = await checkPromoCode(promoCode, currentUserEmailOrPhone);
      if (!promoCheckResult.success) {
        props.handleAlert({ open: true, message: promoCheckResult.message, type: 'error' });
        return;
      }
      const vouchesResult = await addVouchesToUser(userInfo, promoCheckResult.promoUser);
      if (!vouchesResult.success) {
        props.handleAlert({ open: true, message: vouchesResult.message, type: 'error' });
        return;
      }
      if (userInfo) setUserInfo(vouchesResult.currentUser);
      props.handleAlert({ open: true, message: 'You got two new coupon successfully!', type: 'success' });
      return;
    }
    let couponName = '';
    let couponType = '';
    if (coupon === 'SSV3444' || coupon === 'ssv3444') {
      couponName = 'SSV3444';
      couponType = '10';
    } else if (coupon === 'SSV4555' || coupon === 'ssv4555') {
      couponName = 'SSV4555';
      couponType = '10';
    } else {
      couponName = 'SSV50';
      couponType = '50';
    }
    const hasVoucher = userInfo?.vouchers?.some(voucher => {
      return voucher.code === couponName && new Date(voucher.validTill) > new Date();
    });
    if (hasVoucher) {
      setApplyCoupon({
        show: true,
        type: couponType,
        voucher: userInfo?.vouchers?.find(voucher => voucher.code === couponName),
      });
      props.handleAlert({ open: true, message: 'Coupon applied successfully!', type: 'success' });
    } else {
      props.handleAlert({ open: true, message: 'You do not have this voucher!', type: 'error' });
    }
    // handleClose();
  };
  return (
    <Dialog
      maxWidth={'xs'}
      fullWidth={Boolean(true)}
      fullScreen={isMobile ? Boolean(true) : Boolean(false)}
      open={open}
      onClose={() => {
        setCoupon('');
        handleClose();
      }}
      TransitionComponent={Transition}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h3">Gutscheine</Typography>
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <Iconify icon={'octicon:x-24'} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography variant="subtitle1">Bereit zum Einsatz</Typography>
        <Stack spacing={2}>
          {userInfo?.vouchers?.length > 0 ? (
            userInfo?.vouchers?.every(coupon => {
              const validTillDate = new Date(coupon.validTill);
              return validTillDate < new Date();
            }) ? (
              <>No Voucher available </>
            ) : (
              userInfo?.vouchers?.map((coupon, index) => {
                const validTillDate = new Date(coupon.validTill);
                const isExpired = validTillDate < new Date();
                if (isExpired) {
                  return null;
                }
                const options = { month: 'long', day: 'numeric' };
                const formattedDate = validTillDate.toLocaleDateString('de-DE', options);
                return (
                  <Card key={index}>
                    <Box
                      component="img"
                      src={'/assets/voucher.jpg'}
                      sx={{
                        objectFit: 'contain',
                      }}
                    />
                    <Box sx={{ position: 'absolute', top: '6px', left: '16px' }} color={'white'}>
                      <Label>
                        <Typography color={'white'} variant="body">
                          Gültig bis {formattedDate}
                        </Typography>
                      </Label>
                      <Typography variant="h4">{coupon.discount} € Rabatt</Typography>
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: '6px', left: '16px' }} color={'white'}>
                      <Typography variant={'overline'} textTransform={'capitalize'} fontWeight={300}>
                        Spare Jetzt {coupon.discount}€ auf deiner <br /> nächsten Bestellung! mindestbestellwert{' '}
                        {coupon.discount * 2}€
                      </Typography>
                    </Box>
                    <Box sx={{ position: 'absolute', top: '20px', right: { md: '50px', xs: '30px' } }} color={'white'}>
                      <Button
                        variant="contained"
                        size={isMobile ? 'small' : 'medium'}
                        onClick={() => {
                          if (calculateTotalPrice() < 20) {
                            setError(true);
                            return;
                          }
                          setApplyCoupon({
                            show: true,
                            voucher: userInfo?.vouchers?.find(voucher => voucher.code === coupon.code),
                          });
                          props.handleAlert({ open: true, message: 'Coupon applied successfully!', type: 'success' });
                          handleClose();
                        }}
                        sx={{ borderRadius: '26px', backgroundColor: 'white', px: 4 }}
                        className="text-red-500 hover:bg-white">
                        Benutzen
                      </Button>
                    </Box>
                  </Card>
                );
              })
            )
          ) : (
            <>No Voucher available </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {error && (
                <Alert onClose={() => setError(false)} severity={'error'} sx={{ width: '100%' }}>
                  {'Der Gutschein gilt für Bestellungen über 20 €!!'}
                </Alert>
              )}
            </Grid>
            <Grid xs={12} item>
              <Typography variant="subtitle1">Gutscheincode hinzufügen</Typography>
            </Grid>
            <Grid xs={8} item>
              <TextField
                fullWidth
                size="small"
                value={coupon}
                onChange={e => setCoupon(e.target.value)}
                InputProps={{
                  startAdornment: <SvgColor src="/assets/coupon.svg" sx={{ mr: 1 }} />,
                }}
              />
            </Grid>
            <Grid xs={4} item>
              <Button
                fullWidth
                variant="contained"
                sx={{ py: 1, backgroundColor: '#1b1b1b' }}
                onClick={handleAddCoupon}>
                Hinzufügen
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorBoundry(VoucherDialog);
