// CartContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { TrackGoogleAnalyticsEvent } from '../google-analytics';
import { UserContext } from './userProvider';
import { getLocalStorage, setLocalStorage } from '../utils/SecuredLocalStorage';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(
    getLocalStorage('cartItems')?.length > 0 ? getLocalStorage('cartItems') : []
  );
  const { userInfo, setUserInfo } = useContext(UserContext);
  const [applyCoupon, setApplyCoupon] = useState({ show: false, type: '10', voucher: null });
  const [deliveryType, setDeliveryType] = useState(getLocalStorage('deliveryType') ?? 'LIEFERUNG');

  useEffect(() => {
    if (cartItems.length === 0) {
      localStorage.removeItem('cartItems');
      return;
    }
    if (cartItems.length > 0) {
      setLocalStorage('cartItems', cartItems);
    }
    if (calculateTotalPrice() < 20) {
      setApplyCoupon({ show: false, type: '10', voucher: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems]);
  useEffect(() => {
    if (deliveryType) {
      setLocalStorage('deliveryType', deliveryType);
    }
  }, [deliveryType]);

  const addToCart = (item, quantity = 1) => {
    const itemIndex = cartItems.findIndex(
      cartItem => cartItem.name === item.name && cartItem.sizes.size === item.sizes.size
    );
    if (itemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[itemIndex].quantity += quantity;
      setCartItems(updatedCartItems);
    } else {
      const newItem = { ...item, quantity };
      setCartItems(prevCartItems => [...prevCartItems, newItem]);
    }
    TrackGoogleAnalyticsEvent('cart', 'add_to_cart', JSON.stringify({ ...item, quantity }));
  };
  const removeFromCart = itemName => {
    setCartItems(prevCartItems => prevCartItems.filter(item => item.name !== itemName));
  };
  const updateQuantity = (itemName, quantity) => {
    setCartItems(prevCartItems => {
      let updatedPoints = 0;

      const updatedCartItems = prevCartItems
        .map(item => {
          if (item.name === itemName.name && item.sizes.size === itemName.sizes.size) {
            if (item.quantity === 1 && quantity === -1) {
              // Item quantity is 1 and quantity is -1, so remove the item
              if (item?.points) {
                updatedPoints += item?.points;
              }
              return null;
            }
            const newQuantity = item.quantity + quantity;
            if (item.points) {
              updatedPoints += (newQuantity - item.quantity) * item.points;
            }
            return { ...item, quantity: newQuantity };
          }
          return item;
        })
        .filter(item => item !== null);
      if (userInfo) {
        const newUserDetails = { ...userInfo, points: userInfo?.points + updatedPoints };
        setUserInfo(newUserDetails);
      }
      return updatedCartItems;
    });
  };
  const getProductQuantity = productId => {
    return cartItems.reduce((total, cartItem) => {
      return cartItem.id === productId ? total + cartItem.quantity : total;
    }, 0);
  };
  const clearCart = () => {
    let pointsToAdd = 0;
    cartItems.forEach(item => {
      if (item.points) {
        pointsToAdd += item.points * item.quantity;
      }
    });
    if (pointsToAdd > 0) {
      if (userInfo) {
        const newUserDetails = { ...userInfo, points: userInfo.points + pointsToAdd };
        setUserInfo(newUserDetails);
      }
    }
    setCartItems([]);
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    cartItems.forEach(item => {
      if (!item.points) {
        const price = item.sizes.price;
        const quantity = item.quantity;
        totalPrice += price * quantity;
      }
    });
    return totalPrice;
  };
  const calculateTotalQuantity = () => {
    let totalQuantity = 0;
    cartItems.forEach(item => {
      const quantity = item.quantity;
      totalQuantity += quantity;
    });
    return totalQuantity;
  };
  const calculateFinalTotalPrice = () => {
    let totalPrice = 0;
    totalPrice = calculateTotalPrice();
    if (applyCoupon.show) {
      if (applyCoupon.type === '50') {
        // Calculate the discount as 50% of the current totalPrice
        const discountAmount = totalPrice * 0.5;
        // Ensure the discount doesn't exceed 10
        const actualDiscount = Math.min(discountAmount, 10);
        // Decrement totalPrice by the actual discount
        totalPrice -= actualDiscount;
      } else {
        totalPrice -= 10; // Decrement totalPrice by 10
      }
    }
    return totalPrice;
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        updateQuantity,
        setApplyCoupon,
        applyCoupon,
        deliveryType,
        setDeliveryType,
        calculateTotalPrice,
        calculateTotalQuantity,
        calculateFinalTotalPrice,
        getProductQuantity,
      }}>
      {children}
    </CartContext.Provider>
  );
};
