import React, { createContext, useState, useCallback } from 'react';
import { getLocalStorage, setLocalStorage } from '../utils/SecuredLocalStorage';

export const AddressContext = createContext();

export const AddressProvider = ({ children }) => {
  const [addressInfo, setAddressDetails] = useState(getLocalStorage('userAddress') ?? null);

  const setAddressInfo = useCallback(value => {
    setLocalStorage('userAddress', value);
    setAddressDetails(value);
  }, []);

  return (
    <AddressContext.Provider
      value={{
        addressInfo,
        setAddressInfo,
      }}>
      {children}
    </AddressContext.Provider>
  );
};
