import CryptoJS from 'crypto-js';

const encryptionKey = 'helloworld';
// Set data to local storage with optional encryption
export function setLocalStorage(itemName, itemValue, isJSONStringify = true) {
  let serializedData = itemValue;
  if (isJSONStringify) {
    serializedData = JSON.stringify(itemValue);
  }

  // Encrypt the data
  const encryptedData = CryptoJS.AES.encrypt(serializedData, encryptionKey).toString();
  // Store the encrypted data in local storage
  localStorage.setItem(itemName, encryptedData);
}

// Get data from local storage with optional decryption
export function getLocalStorage(itemName, isJSONParse = true) {
  const storedEncryptedData = localStorage.getItem(itemName);

  if (!storedEncryptedData) {
    return null;
  }

  // Decrypt the data
  const bytes = CryptoJS.AES.decrypt(storedEncryptedData, encryptionKey);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  if (isJSONParse) {
    return JSON.parse(decryptedData);
  }

  return decryptedData;
}
