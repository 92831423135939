import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useResponsive from '../../hooks/useResponsive';
import Iconify from '../iconify/Iconify';
import CartDialog from './CartDialog';
import FavoriteDialog from './FavoriteDialog';
import VoucherDialog from './VoucherDialog';
import { signOut, auth } from '../../firebaseconfig';
import { UserContext } from '../../context/userProvider';

// import ContactDialog from './ContactDialog';
// import OrdersDialog from './OrdersDialog';
// import SvgColor from '../svg-color/SvgColor';
import InviteFriendCodeDialog from './InviteFriendCodeDialog';
import OrdersDialog from './MyOrdersDialog';
import { getLocalStorage } from '../../utils/SecuredLocalStorage';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ListIconWithText = ({ icon: Icon, handleClick, text, link, isImage = false }) => (
  <ListItem disablePadding>
    <ListItemButton sx={{ color: theme => theme.palette.primary.main }} onClick={() => handleClick(text, link)}>
      <ListItemIcon
        sx={{
          backgroundColor: 'transparent',
          color: theme => theme.palette.primary.main,
        }}>
        <Avatar
          sx={{
            backgroundColor: 'transparent',
            color: theme => theme.palette.primary.main,
          }}>
          {isImage ? <img src={Icon} alt="" className="h-6 w-6 object-contain" /> : <Icon size={30} />}
        </Avatar>
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  </ListItem>
);

export default function MenuDialog({ open, toggleDialog }) {
  const location = useLocation();
  const { userInfo, setUserInfo } = React.useContext(UserContext);

  const isMobile = useResponsive('down', 'lg');
  const [openDialog, setOpenDialog] = React.useState('');
  const navigate = useNavigate();
  const isUserLoggedIn = () =>
    getLocalStorage('user') !== 'null' && getLocalStorage('user') !== null && getLocalStorage('user')
      ? !!true
      : !!false;
  const SidebarList = {
    isAuthSidebar: {
      OrdersAndFavorite: isMobile
        ? [
            { text: 'Speisekarte', icon: '/assets/images/icons/menu.svg', link: '/menu' },
            { text: 'Warenkorb', icon: '/assets/images/icons/cart.svg' },
            { text: 'Bestellungen', icon: '/assets/images/icons/bag.svg' },
            { text: 'Meine Favoriten', icon: '/assets/images/icons/favorite.svg' },
          ]
        : [
            { text: 'Speisekarte', icon: '/assets/images/icons/menu.svg', link: '/menu' },
            { text: 'Bestellungen', icon: '/assets/images/icons/bag.svg' },
            { text: 'Meine Favoriten', icon: '/assets/images/icons/favorite.svg' },
          ],
      DiscountAndCoupons: [
        { text: 'Freunde einladen', icon: '/assets/images/icons/gift_box_colored.svg' },
        { text: 'Angebote', icon: '/assets/images/icons/offers.svg' },
        {
          text: 'Kundenservice kontaktieren',
          icon: '/assets/images/icons/customersupport.svg',
          link: '/customersupport',
        },
        { text: 'Punkte einlösen', icon: '/assets/images/icons/points.svg', link: '/redeempoints' },
      ],
      JobsAndFranchiseList: [
        {
          text: 'Jobs',
          icon: '/assets/images/icons/jobs.svg',
          link: '/jobsposting/welcome',
        },
        {
          text: 'Franchise',
          icon: '/assets/images/icons/franchise.svg',
          link: '/franchise/franchise1',
        },
      ],
    },
    simpleSidebar: {
      OrdersAndFavorite: isMobile
        ? [
            { text: 'Speisekarte', icon: '/assets/images/icons/menu.svg', link: '/menu' },
            { text: 'Warenkorb', icon: '/assets/images/icons/cart.svg' },
            { text: 'Meine Favoriten', icon: '/assets/images/icons/favorite.svg' },
          ]
        : [
            { text: 'Speisekarte', icon: '/assets/images/icons/menu.svg', link: '/menu' },
            { text: 'Meine Favoriten', icon: '/assets/images/icons/favorite.svg' },
          ],
      DiscountAndCoupons: [
        {
          text: 'Kundenservice kontaktieren',
          icon: '/assets/images/icons/customersupport.svg',
          link: '/customersupport',
        },
      ],
      JobsAndFranchiseList: [
        {
          text: 'Jobs',
          icon: '/assets/images/icons/jobs.svg',
          link: '/jobsposting/welcome',
        },
        {
          text: 'Franchise',
          icon: '/assets/images/icons/franchise.svg',
          link: '/franchise/franchise1',
        },
      ],
    },
  };
  const sidebarData = isUserLoggedIn() ? SidebarList.isAuthSidebar : SidebarList.simpleSidebar;
  const handleClick = (text, link) => {
    if (link) {
      navigate(link);
      toggleDialog();
      return;
    }
    setOpenDialog(text);
    toggleDialog();
  };

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        fullWidth={Boolean(true)}
        fullScreen={isMobile ? Boolean(true) : Boolean(false)}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={toggleDialog}>
        <DialogTitle>
          <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="inherit">Mein Account</Typography>
            <IconButton edge="start" color="inherit" onClick={toggleDialog} aria-label="close">
              <Iconify icon={'octicon:x-24'} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {!isUserLoggedIn() && (
            <DialogContentText id="alert-dialog-slide-description">
              <Grid className="grid grid-cols-2 gap-4">
                <Button
                  onClick={() => handleClick('register', '/login')}
                  className={` ${location.pathname === '/login' ? 'bg-black text-white hover:bg-black active:bg-black' : 'bg-white text-black  hover:bg-black hover:text-white active:text-white'}`}>
                  Anmelden
                </Button>
                <Button
                  onClick={() => handleClick('login', '/login')}
                  className={` ${location.pathname === '/login' ? 'bg-black text-white hover:bg-black active:bg-black' : 'bg-black text-white hover:bg-black active:bg-black'}`}>
                  Account erstellen
                </Button>
              </Grid>
            </DialogContentText>
          )}

          <DialogContentText id="alert-dialog-slide-description">
            <Box sx={{ overflow: 'auto' }}>
              <List>
                {sidebarData.OrdersAndFavorite.map(({ text, icon, link }, index) => (
                  <ListIconWithText key={index} text={text} icon={icon} handleClick={handleClick} link={link} isImage />
                ))}
              </List>
              <Divider />

              <List>
                {sidebarData.DiscountAndCoupons.map(({ text, icon, link }, index) => (
                  <ListIconWithText key={index} text={text} icon={icon} handleClick={handleClick} link={link} isImage />
                ))}
              </List>
              <Divider />
              <List>
                {sidebarData.JobsAndFranchiseList.map(({ text, icon, link }, index) => (
                  <ListIconWithText key={index} text={text} icon={icon} handleClick={handleClick} link={link} isImage />
                ))}
              </List>
              {isUserLoggedIn() && (
                <>
                  <Divider />
                  <List>
                    <ListIconWithText
                      text={'Ausloggen'}
                      icon={'/assets/images/icons/logout.svg'}
                      isImage
                      handleClick={async () => {
                        await signOut(auth).then(() => {
                          setUserInfo(null);
                          // localStorage.removeItem('user');
                          navigate('/');
                        });
                      }}
                    />
                  </List>
                </>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <CartDialog open={Boolean(openDialog === 'Warenkorb')} handleClose={() => setOpenDialog('')} />
      <FavoriteDialog
        open={Boolean(openDialog === 'Meine Favoriten')}
        handleClose={() => {
          setOpenDialog('');
          toggleDialog();
        }}
      />
      <OrdersDialog
        open={Boolean(openDialog === 'Bestellungen')}
        handleClose={() => {
          setOpenDialog('');
          // toggleDialog();
        }}
      />
      <VoucherDialog open={Boolean(openDialog === 'Angebote')} handleClose={() => setOpenDialog('')} />
      {!userInfo?.isSignUpWithGuest && (
        <InviteFriendCodeDialog
          open={Boolean(openDialog === 'Freunde einladen')}
          handleClose={() => setOpenDialog('')}
        />
      )}
      {/* <OrdersDialog open={Boolean(openDialog === 'Orders')} handleClose={() => setOpenDialog('')} /> */}
      {/* <ContactDialog
        open={Boolean(openDialog === 'Kundenservice kontaktieren')}
        handleClose={() => setOpenDialog('')}
      /> */}
    </div>
  );
}
