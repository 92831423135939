import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import ResponsiveAppBar from '../components/navbar/Navbar';
import Footer from '../components/landing/Footer';

export default function SimpleLayout() {
  return (
    <>
      <ResponsiveAppBar />
      <Box
        sx={{
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
        }}>
        <Suspense
          fallback={
            <div className="fixed left-0 top-0 z-[99999] grid h-full w-full place-items-center bg-white">
              <img src="/assets/logo.svg" alt="Diazo" />
            </div>
          }>
          <Outlet />
        </Suspense>
        <Footer />
      </Box>
    </>
  );
}
