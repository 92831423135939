import React, { createContext, useState } from 'react';

export const BannerContext = createContext();

export const BannerProvider = ({ children }) => {
  const [hideBanner, setHideBanner] = useState(sessionStorage.getItem('hideBanner'));
  return (
    <BannerContext.Provider
      value={{
        hideBanner,
        setHideBanner,
      }}
    >
      {children}
    </BannerContext.Provider>
  );
};
