import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getUnixTime } from 'date-fns';
import {
  collection,
  deleteDoc,
  doc,
  firebaseStorage,
  firestore,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from '../../firebaseconfig';

const generateCustomId = prefix => {
  const date = new Date();
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2); // Months are zero-based
  const day = `0${date.getDate()}`.slice(-2);
  const hours = `0${date.getHours()}`.slice(-2);
  const minutes = `0${date.getMinutes()}`.slice(-2);
  const seconds = `0${date.getSeconds()}`.slice(-2);
  const random = Math.floor(100000 + Math.random() * 900000); // Random 6-digit number
  if (prefix) {
    return `${prefix}-${year}${month}${day}_${hours}${minutes}${seconds}_${random}`;
  }
  return `${year}${month}${day}_${hours}${minutes}${seconds}_${random}`;
};

export const AddDataInFireBaseCollection = async (data, collectionName) => {
  const customId = generateCustomId();
  const ref = doc(firestore, collectionName, customId);
  await setDoc(ref, data, { merge: true });
  const getRef = doc(firestore, collectionName, ref.id);
  const res = await getDoc(getRef);
  return res.data()
    ? {
        data: { ...res.data(), id: res.id },
        message: 'Success!',
        code: 1,
      }
    : {
        message: 'Something went wrong!',
        code: 0,
      };
};

export async function uploadImages(selectedFiles, location) {
  if (selectedFiles.length === 0) {
    return [];
  }

  if (selectedFiles.length === 1) {
    const file = selectedFiles[0];
    const imageRef = ref(firebaseStorage, `${location}/${file.name}`);
    await uploadBytes(imageRef, file);
    const imageUrl = await getDownloadURL(imageRef);
    return imageUrl;
  }

  const imageUrls = [];
  // eslint-disable-next-line
  for (const file of selectedFiles) {
    // Generate a unique filename by adding a timestamp
    const timestamp = getUnixTime(new Date());
    const fileName = `${file.name}_${timestamp}`;
    const imageRef = ref(firebaseStorage, `${location}/${fileName}`);
    // eslint-disable-next-line
    await uploadBytes(imageRef, file);
    // eslint-disable-next-line
    const imageUrl = await getDownloadURL(imageRef);
    imageUrls.push(imageUrl);
  }
  return imageUrls;
}

export async function isCurrentUserAlreadyHaveAnyCookingStatusOrder(userData) {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(firestore, 'orders'),
        where('user', '==', userData?.phone || userData?.email),
        where('status', '==', 'cooking')
      )
    );
    const isCooking = querySnapshot.size > 0;
    return isCooking;
  } catch (error) {
    console.log('Error fetching location:', error);
    return false;
  }
}
export async function getMinimumOrderAmount(postalCode) {
  try {
    const docRef = doc(firestore, 'minimumOrderValue', postalCode);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const results = docSnap.data();
      const minValue = Object.values(results)[0];
      return minValue;
    }
    return 9.99;
  } catch (error) {
    console.log('Error fetching location:', error);
    return 9.99;
  }
}
export async function getDeliveryChargeById(id) {
  try {
    const docRef = doc(firestore, 'deliveryCharges', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const results = docSnap.data();
      const deliveryCharge = Object.values(results)[0];
      return deliveryCharge;
    }
    return null;
  } catch (error) {
    console.log('Error fetching delivery charge:', error);
    return null;
  }
}
export async function getSingleOrderDetails(id) {
  try {
    const docRef = doc(firestore, 'orders', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const order = docSnap.data();
      return order;
    }
    return null;
  } catch (error) {
    console.log('Error fetching delivery charge:', error);
    return null;
  }
}

const checkPromoCode = async (promoCode, userDetails) => {
  try {
    const userQuerySnapchat = collection(firestore, 'users');
    const filteredUsersQuery = query(userQuerySnapchat, where('promoCode', '==', promoCode));
    const querySnapshot = await getDocs(filteredUsersQuery);
    if (querySnapshot.empty) {
      return { success: false, message: 'No matching documents' };
    }
    let promoUser = null;
    querySnapshot.forEach(doc => {
      if (doc.id === userDetails) {
        promoUser = null; // User can't use their own promo code
      } else {
        promoUser = doc.data();
      }
    });
    if (!promoUser) {
      return { success: false, message: 'You cannot use your own promo code' };
    }
    return { success: true, promoUser, message: 'Promo code is valid' };
  } catch (error) {
    console.log(error);
    return { success: false, message: `Error checking promo code: ${error.message}` };
  }
};

const addVouchesToUser = async (currentUser, promoUser) => {
  const newVoucher = {
    code: 'SSV4555',
    discount: 10,
    title: 'PROMOTIONAL',
    validTill: new Date(new Date().setMonth(new Date().getMonth() + 3)).toISOString(),
  };
  try {
    const usersCollection = collection(firestore, 'users');
    const currentUserRef = doc(usersCollection, currentUser.phone || currentUser.email);
    const promoUserCollection = collection(firestore, 'users');
    const promoUserRef = doc(promoUserCollection, promoUser.phone || promoUser.email);
    const newUserVouchers = [...currentUser.vouchers, newVoucher, newVoucher];
    const newPromoUserVouchers = [...promoUser.vouchers, newVoucher, newVoucher];
    const isUserAlreadyInPromoUserList = promoUser.referredUsers.includes(currentUser.phone || currentUser.email);
    if (isUserAlreadyInPromoUserList) {
      return { success: false, message: 'You have already used this promo code' };
    }
    // Add voucher to the current user
    await updateDoc(currentUserRef, {
      vouchers: newUserVouchers,
      referredBy: promoUser.email || promoUser.phoneNumber,
    });
    // Add voucher to the promo user
    await updateDoc(promoUserRef, {
      vouchers: newPromoUserVouchers,
      referredUsers: [...promoUser.referredUsers, currentUser.phone || currentUser.email],
    });
    const userDoc = await getDoc(currentUserRef);
    return { success: true, message: 'Vouches added successfully', currentUser: userDoc.data() };
  } catch (error) {
    console.log(error);
    return { success: false, message: `Error adding vouches: ${error.message}` };
  }
};

const getAllRedeemPointRewards = async () => {
  try {
    const rewardsCollection = collection(firestore, 'rewards');
    const rewardsSnapshot = await getDocs(rewardsCollection);

    const rewardsList = [];
    rewardsSnapshot.forEach(doc => {
      rewardsList.push({ id: doc.id, ...doc.data() });
    });

    return rewardsList;
  } catch (error) {
    console.error('Error getting rewards:', error);
    throw error;
  }
};
const fetchUserDetails = async user => {
  try {
    const userDoc = doc(firestore, 'users', user.phone || user.email);
    const docSnap = await getDoc(userDoc);
    if (docSnap.exists()) {
      return docSnap.data();
    }
    return null;
  } catch (error) {
    console.error('Error fetching user details:', error);
    return null;
  }
};
const fetchUserOrders = async userInfo => {
  try {
    const querySnapshot = await getDocs(query(collection(firestore, 'orders'), where('user', '==', userInfo)));
    const documents = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    return documents;
  } catch (error) {
    console.error('Error fetching documents: ', error);
    return [];
  }
};
async function moveOrderToOrdersCollection(orderId) {
  const unconfirmedOrderRef = doc(firestore, 'unconfirmedOrders', orderId);
  const orderRef = doc(firestore, 'orders', orderId);
  try {
    const orderSnapshot = await getDoc(unconfirmedOrderRef);
    if (!orderSnapshot.exists()) {
      console.log('No such document!');
      return;
    }
    const orderData = orderSnapshot.data();
    await setDoc(orderRef, orderData);
    await deleteDoc(unconfirmedOrderRef);
  } catch (error) {
    console.error('Error moving order: ', error);
  }
}
async function getMenuItemFromCategoryId(menuCategoryId, collectionName = 'menuItems') {
  try {
    const querySnapshot = await getDocs(
      query(collection(firestore, collectionName), where('categoryId', '==', menuCategoryId))
    );
    const documents = querySnapshot.docs.map(doc => ({
      id: doc.id,
      data: doc.data(),
    }));
    return documents;
  } catch (error) {
    console.error('Error fetching documents: ', error);
    return [];
  }
}
async function getExtraFromCategoryId(menuCategoryId) {
  try {
    const querySnapshot = await getDocs(
      query(collection(firestore, 'extras'), where('categoryId', '==', menuCategoryId))
    );
    const documents = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    return documents;
  } catch (error) {
    console.error('Error fetching documents: ', error);
    return [];
  }
}
export {
  fetchUserDetails,
  checkPromoCode,
  fetchUserOrders,
  addVouchesToUser,
  getAllRedeemPointRewards,
  moveOrderToOrdersCollection,
  getMenuItemFromCategoryId,
  getExtraFromCategoryId,
};
