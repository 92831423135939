import ReactGA4 from 'react-ga4';

const InitializeGoogleAnalytics = () => {
  // Initialize GA4 - Add your measurement ID
  ReactGA4.initialize('G-FNQ9TLTQL9');
};

const TrackGoogleAnalyticsEvent = (category, action, label) => {
  // console.log('GA event:', category, ':', action, ':', label);

  // Send GA4 Event
  ReactGA4.event({
    category,
    action,
    label,
  });
};

// export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent };
