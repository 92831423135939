import { Box, Card, DialogActions, DialogContent, Grid, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import React, { useContext } from 'react';
import useResponsive from '../../hooks/useResponsive';
import { fShortenNumber } from '../../utils/formatNumber';
import Iconify from '../iconify/Iconify';
import { FavoriteContext } from '../../context/favoriteContext';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function FavoriteDialog({ open, handleClose }) {
  const isMobile = useResponsive('down', 'sm');
  const { favoriteItems, alreadyFavorited, addToFavorite } = useContext(FavoriteContext);

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        fullWidth={Boolean(true)}
        fullScreen={isMobile ? Boolean(true) : Boolean(false)}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll="paper">
        <DialogActions sx={{ display: 'flex', justifyContent: 'start', px: 2 }}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <Iconify icon={'teenyicons:left-outline'} />
          </IconButton>
          <Typography variant="h3">Favoriten</Typography>
        </DialogActions>
        <DialogContent sx={{ display: 'flex', alignItems: favoriteItems.length ? 'start' : 'center' }}>
          {favoriteItems.length === 0 ? (
            <Stack alignItems={'center'} justifyContent={'space-evenly'} height={{ md: '100%', xs: '80%' }}>
              <Typography sx={{ textAlign: 'center' }} className="md:max-w-[80%] lg:max-w-[65%]">
                Bestelle einfach nach und speichere deine Favoriten auf deinem {isMobile && <br />} Diazo-Konto.
              </Typography>
              <Box
                component="img"
                src={'/assets/favourite.jpg'}
                sx={{
                  width: 250,
                  height: 250,
                  objectFit: 'contain',
                }}
              />
            </Stack>
          ) : (
            <Stack my={3} width={'100%'}>
              {favoriteItems.map((item, i) => (
                <Card key={i} sx={{ padding: 1, my: 0.5 }}>
                  <Grid container>
                    <Grid item xs={3}>
                      <Box
                        component="img"
                        src={item.itemImages[0]}
                        sx={{
                          width: 100,
                          height: 100,
                          objectFit: 'contain',
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'space-between'}
                      py={2}
                      pl={2}>
                      <Box>
                        <Typography variant="subtitle1">{item.name}</Typography>
                      </Box>
                      <Typography variant="h5" color={'#E36C0E'}>
                        {fShortenNumber(item.sizes[0].price)} €
                      </Typography>
                    </Grid>
                    <Grid item xs={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                      <Box
                        sx={{
                          color: theme =>
                            alreadyFavorited({ name: item.name }) ? theme.palette.error.dark : theme.palette.grey[500],
                          height: 40,
                          width: 40,
                          backgroundColor: theme => theme.palette.background.default,
                          borderRadius: '50%',
                          border: 1,
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={e => {
                          e.stopPropagation();
                          addToFavorite(item);
                        }}>
                        <Iconify icon={'mdi:heart'} />
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              ))}
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
