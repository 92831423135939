export const diazoWeddingAreas = [
  '10115',
  '10117',
  '10119',
  '10551',
  '10553',
  '10555',
  '10557',
  '10559',
  '13347',
  '13349',
  '13351',
  '13353',
  '13355',
  '13357',
  '13359',
  '13407',
  '13409',
  '13405',
  '13403',
  '10435',
];

export const diazoNeukolinAreas = [
  '12051',
  '12049',
  '12055',
  '12043',
  '12057',
  '12099',
  '12457',
  '12099',
  '12457',
  '12359',
  '12109',
  '12059',
  '12045',
  '12047',
  '12435',
  '10997',
  '10999',
  '10967',
  '12437',
  '12107',
  '12349',
  '12351',
  '12353',
  '12357',
  '12487',
  '12439',
  '12459',
  '12107',
  '12277',
  '12305',
  '12249',
  '12307',
  '12309',
  '12279',
  '12355',
  '12489',
  '12053',
  '12347',
];

export const diazoSpandauAreas = [
  '13585',
  '13587',
  '13583',
  '13581',
  '13591',
  '13595',
  '13597',
  '13599',
  '13589',
  '13629',
  '13505',
  '14055',
  '14089',
  '14193',
  '14612',
  '14621',
  '14624',
  '16727',
  '16761',
];

export const diazoPrenzlauerbergAreas = [
  '10437',
  '10439',
  '13187',
  '13189',
  '10405',
  '10407',
  '13089',
  '10409',
  '10243',
  '13156',
  '13158',
  '10243',
  '10247',
  '10249',
  '13088',
  '13089',
];

export const diazoSchonebergAreas = [
  '10829',
  '12101',
  '12103',
  '10827',
  '12157',
  '12159',
  '10823',
  '10825',
  '10783',
  '10781',
  '10965',
  '10715',
  '10779',
  '10717',
  '10777',
  '10789',
  '10719',
  '12161',
  '10785',
  '10963',
  '12105',
  '12169',
  '10713',
  '10707',
  '10709',
  '12163',
  '14197',
  '12167',
  '14199',
  '10711',
  '10623',
  '10787',
  '10963',
  '10629',
  '10625',
  '10627',
  '14199',
  '10711',
  '14057',
  '12247',
  '12203',
  '14195',
  '10587',
  '10585',
  '12205',
  '12207',
  '14193',
];

export const diazoLocations = [
  ...diazoWeddingAreas,
  ...diazoPrenzlauerbergAreas,
  ...diazoNeukolinAreas,
  ...diazoSpandauAreas,
  ...diazoSchonebergAreas,
];

const restaurantTimings = {
  'Diazo-Wedding': {
    weekdays: { start: '11:00', end: '04:00' },
    weekends: { start: '11:00', end: '06:00' },
  },
  'Diazo-Neukolin': {
    weekdays: { start: '15:00', end: '04:00' },
    weekends: { start: '15:00', end: '06:00' },
  },
  'Diazo-Spandau': {
    weekdays: { start: '17:00', end: '04:00' },
    weekends: { start: '17:00', end: '05:00' },
  },
  'Diazo-Prenzlauerberg': {
    weekdays: { start: '15:00', end: '04:00' },
    weekends: { start: '15:00', end: '06:00' },
  },
  'Diazo-Schoneberg': {
    weekdays: { start: '11:00', end: '04:00AM' },
    weekends: { start: '11:00', end: '06:00AM' },
  },
};

const getTimings = restaurantName => {
  const currentTime = new Date();
  const dayOfWeek = currentTime.getDay();
  const timings = restaurantTimings[restaurantName];
  if (!timings) {
    return 'Invalid restaurant name';
  }
  const isWeekend = dayOfWeek === 5 || dayOfWeek === 6; // Updated weekend check
  const { start, end } = isWeekend ? timings.weekends : timings.weekdays;
  return `${start} - ${end}`;
};

const getClosingTime = restaurantName => {
  if (!restaurantName) return '';
  const currentTime = new Date();
  const dayOfWeek = currentTime.getDay();
  const timings = restaurantTimings[restaurantName];
  if (!timings) {
    return 'Invalid restaurant name';
  }
  const isWeekend = dayOfWeek === 5 || dayOfWeek === 6; // Updated weekend check
  const { end } = isWeekend ? timings.weekends : timings.weekdays;
  return end;
};

// Helper function to convert 24-hour format to 12-hour format with AM/PM
const convertTo12HourFormat = time => {
  const [hour, minute] = time.split(':');
  const hours = parseInt(hour, 10);
  // eslint-disable-next-line
  const minutes = minute ? minute : '00';
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  return `${formattedHours}:${minutes}${period}`;
};

const isRestaurantOpen = restaurantName => {
  // Get current time in Berlin timezone
  const berlinTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' });
  const currentTime = new Date(berlinTime);
  const day = currentTime.getDay();
  const hour = currentTime.getHours();
  const minute = currentTime.getMinutes();
  const timings = restaurantTimings[restaurantName];
  if (!timings) return false;
  const isWeekend = day === 5 || day === 6;
  const { start, end } = isWeekend ? timings.weekends : timings.weekdays;

  // Convert start and end time strings to hours and minutes
  const [startHour, startMinute] = start.split(':').map(Number);
  const [endHour, endMinute] = end.replace('AM', '').split(':').map(Number);

  // Convert start and end times to absolute minutes
  const startTimeInMinutes = startHour * 60 + startMinute;
  const endTimeInMinutes = (endHour + (end.includes('AM') ? 24 : 0)) * 60 + endMinute;
  const currentTimeInMinutes = hour * 60 + minute;
  // Check if current time is within the range
  if (startTimeInMinutes <= endTimeInMinutes) {
    // Same day range
    return currentTimeInMinutes >= startTimeInMinutes && currentTimeInMinutes < endTimeInMinutes;
  }
  return currentTimeInMinutes >= startTimeInMinutes || currentTimeInMinutes < endTimeInMinutes;
};

// Updated functions to convert times if needed
const getRestaurantTimings = restaurantName => {
  const timings = getTimings(restaurantName);
  if (timings === 'Invalid restaurant name') return timings;
  const [start, end] = timings.split(' - ');
  return `${convertTo12HourFormat(start)} - ${convertTo12HourFormat(end)}`;
};

const getRestaurantClosingTime = restaurantName => {
  const closingTime = getClosingTime(restaurantName);
  if (closingTime) return convertTo12HourFormat(closingTime);
  return closingTime;
};

export { getRestaurantTimings, getRestaurantClosingTime, isRestaurantOpen };
