import React, { createContext, useState, useCallback, useEffect } from 'react';
import { fetchUserDetails } from '../utils/firebase/FirebaseCommon';
import { getLocalStorage, setLocalStorage } from '../utils/SecuredLocalStorage';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfo, setUserDetails] = useState(getLocalStorage('user') ?? null);
  useEffect(() => {
    const savedUser = getLocalStorage('user') ?? null;
    if (!savedUser) return;
    (async () => {
      const userDetails = await fetchUserDetails(savedUser);
      if (userDetails) setUserInfo(userDetails);
    })();
    // eslint-disable-next-line
  }, []);
  const setUserInfo = useCallback(data => {
    if (data === null) {
      localStorage.removeItem('user');
      setUserDetails(null);
    }
    if (data) {
      setLocalStorage('user', data);
      setUserDetails(data);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
      }}>
      {children}
    </UserContext.Provider>
  );
};
