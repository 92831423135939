import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Container, DialogActions, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { HiOutlineLockClosed } from 'react-icons/hi2';
import { styled } from '@mui/material/styles';
import useResponsive from '../../hooks/useResponsive';
import Iconify from '../iconify/Iconify';
import { fetchUserOrders } from '../../utils/firebase/FirebaseCommon';
import { UserContext } from '../../context/userProvider';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  backgroundColor: 'white',
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}));

export default function OrdersDialog({ open, handleClose }) {
  const isMobile = useResponsive('down', 'sm');
  const navigate = useNavigate();
  const pageRef = useRef({
    orders: [],
    isLoading: false,
  });
  const uiRefresh = useState(-1)[1];
  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      pageRef.current.isLoading = true;
      uiRefresh(Date.now());
      try {
        const orders = fetchUserOrders(userInfo?.phone || userInfo?.email);
        if (orders.length > 0) {
          const sortedOrder = orders?.sort((a, b) => b.dateTime - a.dateTime);
          const formattedDateOrders = sortedOrder.map(order => {
            const date = new Date(order.dateTime.toDate());
            const formattedDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
            return { ...order, dateTime: formattedDate };
          });
          pageRef.current.orders = formattedDateOrders;
        }
      } catch (error) {
        console.log(error);
      }
      pageRef.current.isLoading = false;
      uiRefresh(Date.now());
    })();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        fullWidth={Boolean(true)}
        fullScreen={isMobile ? Boolean(true) : Boolean(false)}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll="paper">
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'start',
            px: {
              xs: 2,
              md: 4,
            },
            pt: 3,
          }}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <Iconify icon={'teenyicons:left-outline'} />
          </IconButton>
          <Typography variant="h4" className="ml-2">
            Bestellungen
          </Typography>
        </DialogActions>
        <StyledRoot>
          <Container maxWidth="sm">
            <StyledContent>
              {pageRef.current.isLoading ? (
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  textAlign={'center'}
                  spacing={3}
                  width={'100%'}
                  height={'20rem'}>
                  <CircularProgress />
                </Stack>
              ) : pageRef.current.orders?.length === 0 || userInfo.isSignUpWithGuest ? (
                <Box className="flex flex-col items-center justify-center gap-4 py-4 sm:py-4 md:pb-[3rem]">
                  <HiOutlineLockClosed size={30} />
                  <div className="">
                    <Typography variant="h4" className="text-center">
                      Noch keine Bestellungen
                    </Typography>
                    <Typography variant="body1" className="text-center" gutterBottom>
                      Du hast noch keine Bestellung getätigt.
                    </Typography>
                  </div>

                  <Button
                    onClick={() => {
                      navigate('/menu');
                    }}
                    className="bg-black px-4 text-white hover:bg-black">
                    <Typography variant="body1">Menü fortsetzen</Typography>
                  </Button>
                </Box>
              ) : (
                <Box className="flex flex-col gap-4 overflow-y-auto">
                  {pageRef.current.orders?.map((order, index) => (
                    <Box
                      onClick={() => {
                        navigate(`/order/${order.id}`);
                        handleClose();
                      }}
                      className="flex rounded-lg border hover:cursor-pointer"
                      key={index}>
                      <Box className="flex items-center justify-center border px-4">
                        <Typography
                          variant={'h5'}
                          noWrap
                          className="underline-offset-[3px]"
                          sx={{
                            color: theme => theme.palette.primary.main,
                            textDecoration: 'underline',
                            fontFamily: 'Times New Roman, Times, serif',
                            textDecorationThickness: 2,
                          }}>
                          DIAZO
                        </Typography>
                      </Box>
                      <Box className="flex flex-col gap-3 px-2 py-2 md:py-4">
                        <Typography variant="h5" className="leading-3">
                          {order.branch}
                        </Typography>
                        <Typography variant="body2" className="leading-3">
                          {order.address}
                        </Typography>
                        <Box className={``}>
                          <Typography variant="body1" className="leading-3">
                            {order.dateTime} -
                            {order.status === 'delivered'
                              ? 'Delivered'
                              : order.status === 'onTheWay'
                                ? 'On the way'
                                : order.status === 'cancelled'
                                  ? 'Cancelled'
                                  : 'Cooking'}
                          </Typography>
                        </Box>

                        <Typography variant="body1" className="font-semibold leading-3 text-orange-500">
                          {order.totalPrice}€
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </StyledContent>
          </Container>
        </StyledRoot>
      </Dialog>
    </div>
  );
}
