import styled from '@emotion/styled';
import React, { useContext } from 'react';
import Lottie from 'lottie-react';
import { Box, Button, Container, Dialog, IconButton, Slide, Typography } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import Iconify from '../iconify/Iconify';
import ErrorBoundry from '../alerts/ErrorBoundary';
import { UserContext } from '../../context/userProvider';
import InviteHeaderLottie from '../../lottie/invite_header_lottie.json';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  backgroundColor: 'white',
}));
const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}));

function InviteFriendCodeDialog({ open, handleClose, ...props }) {
  const { userInfo } = useContext(UserContext);
  const isMobile = useResponsive('down', 'md');
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Hungrig?',
          text: 'Spare 10 € bei deinen ersten 2 Diazo Bestellungen von mindestens 20 €. Es gelten die AGB. Meinen Code beim Bezahlen verwenden: Diazo-MtwI652e ',
          url: `https://qrfy.com/p/VqE_jqq9Tn?utm_source=qrcode&utm_medium=app&utm_campaign=22999758`,
        });
      } catch (error) {
        console.log('Error sharing content:', error);
      }
    } else {
      console.log('Web Share API not supported in this browser');
      // Fallback for unsupported browsers can be implemented here
    }
  };
  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth={Boolean(true)}
      fullScreen={isMobile ? Boolean(true) : Boolean(false)}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      scroll="body">
      <IconButton
        edge="start"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
        sx={{
          'position': 'absolute',
          'right': {
            xs: 20,
            md: 30,
          },
          'top': 20,
          'color': theme => theme.palette.grey[500],
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Iconify icon={'octicon:x-24'} />
      </IconButton>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Box className="flex h-full w-full flex-col justify-center" sx={{ marginTop: { xs: 5 } }}>
              <Lottie
                animationData={InviteHeaderLottie}
                loop={Boolean(true)}
                style={{ height: isMobile ? 200 : 300 }}
              />
              <Typography variant="h6" textAlign={'center'} gutterBottom>
                Teilen macht Freude:
              </Typography>
              <Typography variant="h6" textAlign={'center'} gutterBottom>
                20€ für sie, 20€ für dich
              </Typography>
              <Typography variant="body1" textAlign={'center'} gutterBottom>
                Schenke deinen Liebsten 20€ Rabatt für ihre erste Bestellung, indem du diesen Code teilst. Sobald sie
                ihn benutzt haben, erhältst du einen 20€-Gutschein im Checkout!
              </Typography>
              <Typography variant="subtitle1" textAlign={'center'} gutterBottom>
                Code teilen & Gutschein im Checkout finden, sobald der Code benutzt wurde:
              </Typography>
              <Box className="relative mx-auto flex max-w-[50%] items-center justify-center rounded-lg border border-dotted border-green-500 p-2 ">
                <Typography variant="body1">{userInfo?.promoCode}</Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={async () => {
                    await navigator.clipboard.writeText(userInfo?.promoCode);
                    props.handleAlert({
                      open: true,
                      message: 'In die Zwischenablage kopiert! Vergessen Sie nicht, es mit Ihren Freunden zu teilen!',
                      type: 'success',
                    });
                  }}
                  aria-label="close"
                  sx={{
                    marginLeft: 2,
                    color: theme => theme.palette.grey[500],
                  }}>
                  <Iconify icon={'clarity:copy-line'} />
                </IconButton>
              </Box>
              <Button
                onClick={handleShare}
                type="button"
                className="mx-auto mt-4 w-full max-w-[70%] bg-black p-2 text-white hover:bg-black">
                Jetzt einladen
              </Button>
            </Box>
          </StyledContent>
        </Container>
      </StyledRoot>
    </Dialog>
  );
}

export default ErrorBoundry(InviteFriendCodeDialog);
