import { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// routes
import Router from './routes';
import './index.css';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { BannerProvider } from './context/bannerProvider';
import { CartProvider } from './context/cartProvider';
import { FavoriteProvider } from './context/favoriteContext';
import { InitializeGoogleAnalytics } from './google-analytics';
import { UserProvider } from './context/userProvider';
import { AddressProvider } from './context/AddressProvider';

// ----------------------------------------------------------------------
const stripePromise = loadStripe(
  'pk_live_51MqxE8KOWD1j2Hl0gUcU3RPxd316HWi0V9gFOQKUAZWYKR13ytJVHMZTITUfonUNFWM9JtaJayvNiWzkaeCDwZ3x00lUD8XaSY'
);

// const options = {
// passing the client secret obtained from the server
// clientSecret: '{{CLIENT_SECRET}}',
// };

export default function App() {
  useEffect(() => {
    InitializeGoogleAnalytics();
  }, []);

  return (
    <ThemeProvider>
      <UserProvider>
        <CartProvider>
          <AddressProvider>
            <FavoriteProvider>
              <BannerProvider>
                <ScrollToTop />
                <Elements stripe={stripePromise}>
                  <Router />
                </Elements>
              </BannerProvider>
            </FavoriteProvider>
          </AddressProvider>
        </CartProvider>
      </UserProvider>
    </ThemeProvider>
  );
}
