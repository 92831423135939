import { lazy } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import SimpleLayout from './layouts/SimpleLayout';
import DashboardLayout from './layouts/WithCartLayout';
import { getLocalStorage } from './utils/SecuredLocalStorage';
// Open Routes
const LandingPage = lazy(() => import('./pages/LandingPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const MenuPage = lazy(() => import('./pages/MenuPage'));
const RedeemPointProductsPage = lazy(() => import('./pages/RedeemPointProductsPage'));
const JobsPage = lazy(() => import('./pages/JobsPage'));
const FranchisesPage = lazy(() => import('./pages/FranchisesPage'));
const FeedbackPage = lazy(() => import('./pages/FeedbackPage'));
const Contact = lazy(() => import('./components/contact/Contact'));
const DetailPage = lazy(() => import('./pages/DetailPage'));
const Page404 = lazy(() => import('./pages/Page404'));
const CustomerSupportPage = lazy(() => import('./pages/CustomerSupportPage'));
const CheckoutPage = lazy(() => import('./pages/CheckoutPage'));
const SingleOrderDetailsPage = lazy(() => import('./pages/SingleOrderDetailsPage'));
// ----------------------------------------------------------------------
const CheckAuth = () => getLocalStorage('user') !== null && getLocalStorage('user') !== 'null';

export default function Router() {
  const routes = useRoutes([
    {
      element: <SimpleLayout />,
      children: [
        {
          path: '/',
          element: <LandingPage />,
          index: true,
        },
        { element: <Navigate to="/" />, index: true },
        { path: '/franchise/:slug', element: <FranchisesPage /> },
        { path: '/jobsposting/:slug', element: <JobsPage /> },
        { path: '/customersupport', element: <CustomerSupportPage /> },
        { path: '/contact', element: <Contact />, index: true },
        { path: '/feedback', element: <FeedbackPage />, index: true },
        { path: '/redeempoints', element: <RedeemPointProductsPage />, index: true },
        {
          path: '/login',
          element: CheckAuth() ? <Navigate to="/menu" /> : <LoginPage />,
        },
        {
          path: '/order/:id',
          element: CheckAuth() ? <SingleOrderDetailsPage /> : <Navigate to={'/menu'} />,
          index: true,
        },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: 'menu', element: <MenuPage />, index: true },
        {
          path: 'checkout',
          element: <CheckoutPage />,
          index: true,
        },
        {
          path: 'detail',
          element: CheckAuth() ? <DetailPage /> : <Navigate to={'/menu'} />,
          index: true,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
