import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Drawer } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
// import Scrollbar from '../../../components/scrollbar';
import Cart from '../components/checkout/Cart';
import { BannerContext } from '../context/bannerProvider';

const NAV_WIDTH = 320;

LeftSideCart.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function LeftSideCart({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const [scrollY, setScrollY] = useState(0);
  const { hideBanner } = useContext(BannerContext);
  const handleScroll = () => setScrollY(window.scrollY);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Box
      sx={{
        'height': 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}>
      <Box
        sx={{
          px: 2.5,
          // py: { lg: 5.5 },
          display: 'inline-flex',
        }}
      />
      <Cart />
      {/* <Box sx={{ flexGrow: 1 }} /> */}
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}>
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          anchor="right"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              // bgcolor: 'background.default',
              borderLeftStyle: 'dashed',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              height: '100%',
              overflow: 'hidden',
              marginTop: {
                lg: scrollY < 80 ? (!hideBanner ? 11 : 7) : '0px',
              },
              transition: 'margin-top 0.3s ease-in-out',
            },
          }}>
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
            height: '100%',
            overflow: 'hidden',
          }}>
          {renderContent}
        </Drawer>
      )}{' '}
    </Box>
  );
}
