import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, Stack, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import React, { useContext, useState } from 'react';
import { collection, doc, firestore, getDoc, setDoc, updateDoc } from '../../firebaseconfig';
import { MuiPhone } from '../../sections/auth/login/PhoneNumber';
import Iconify from '../iconify/Iconify';
import { UserContext } from '../../context/userProvider';
import { getLocalStorage } from '../../utils/SecuredLocalStorage';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function GuestUserDialog({ open, handleClose }) {
  const { setUserInfo } = useContext(UserContext);
  const [phone, setPhone] = useState('');
  const [error, setError] = useState({ name: '', phone: '' });
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    if (name.length === 0) {
      setError({ ...error, name: 'Name is required' });
      return;
    }
    setError({ ...error, name: '' });
    const formattedPhoneNumber = phone.replace(/[^\d+]/g, ''); // Remove non-numeric characters

    if (formattedPhoneNumber.length < 10) {
      setError({ ...error, phone: 'Phone is required' });
      return;
    }

    setError({ name: '', phone: '' });
    setIsLoading(true);
    const userRef = doc(collection(firestore, 'users'), formattedPhoneNumber);
    const userDoc = await getDoc(userRef);
    let userInfo = {};
    if (userDoc.exists()) {
      await updateDoc(userRef, {
        name,
        phone: formattedPhoneNumber,
      });
      userInfo = userDoc.data();
    } else {
      let userAddress = '';
      const userData = getLocalStorage('userAddress');
      userAddress = userData;
      userInfo = {
        name,
        phone: formattedPhoneNumber,
        email: '',
        isPhoneNumberVerified: false,
        isSignUpWithApple: false,
        isSignUpWithGoogle: false,
        isSignUpWithGuest: false,
        userAddress: userAddress?.userAddress ?? null,
        userLatitude: userAddress?.userLatitude ?? null,
        userLongitude: userAddress?.userLongitude ?? null,
        vouchers: [
          { code: 'SSV3444', discount: 10, title: 'NEW USER', validTill: '2024-09-21 02:03:39.325986' },
          { code: 'SSV3444', discount: 10, title: 'NEW USER', validTill: '2024-09-21 02:03:39.325986' },
          { code: 'SSV3444', discount: 10, title: 'NEW USER', validTill: '2024-09-21 02:03:39.325986' },
        ],
        usedVouchers: [],
      };
      await setDoc(userRef, userInfo);
    }
    setUserInfo(userInfo);
    setIsLoading(false);
    handleClose();
  };

  return (
    <div>
      <Dialog
        maxWidth={'xs'}
        fullWidth={Boolean(true)}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <DialogActions sx={{ display: 'flex', justifyContent: 'end', px: 2 }}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <Iconify icon={'octicon:x-24'} />
          </IconButton>
        </DialogActions>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack mb={1} width={'100%'} spacing={1}>
              <Typography variant="h5" textAlign={'center'}>
                Telefonnummer hinzufügen
              </Typography>
              <Typography variant="body2" textAlign={'center'}>
                Wir werden diese Telefonnummer verwenden, um lhnen ihre Bestellung zuzustellen.
              </Typography>
            </Stack>
            <Stack mb={1} width={'100%'} spacing={1}>
              <TextField
                fullWidth
                name="name"
                value={name}
                // required={Boolean(true)}
                onChange={e => setName(e.target.value)}
                type="text"
                placeholder="Name"
                error={Boolean(error.name)}
                helperText={Boolean(error.name) && error.name}
              />
              <Stack>
                <Typography variant="subtitle1" textTransform={'capitalize'}>
                  Phone Number
                </Typography>
                <MuiPhone value={phone} setPhone={setPhone} error={error} />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 2 }}>
            <LoadingButton
              fullWidth
              sx={{
                'backgroundColor': '#1b1b1b',
                '&:hover': {
                  backgroundColor: '#1b1b1b',
                },
              }}
              loading={isLoading}
              type="submit"
              variant="contained">
              Anmelden
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
