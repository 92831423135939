import * as React from 'react';
import Cart from '../checkout/Cart';
import DialogWrapper from './DialogWrapper';

const CartDialog = ({ open, handleClose }) => (
  <DialogWrapper title="Warenkorb" open={open} handleClose={handleClose}>
    <Cart />
  </DialogWrapper>
);

export default CartDialog;
