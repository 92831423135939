import { DiazoOutletInfo } from '../constants/DiazoOutletInfo';

const {
  diazoWeddingAreas,
  diazoPrenzlauerbergAreas,
  diazoNeukolinAreas,
  diazoSchonebergAreas,
  diazoSpandauAreas,
} = require('../config');

export const extractPostalCodeFromAddress = address => {
  const postalCodeRegex = /\b\d{5}\b/;
  const match = address.match(postalCodeRegex);
  const postalCode = match ? match[0] : '';
  return postalCode;
};

export function findBranch(searchString) {
  if (diazoWeddingAreas.includes(extractPostalCodeFromAddress(searchString))) {
    return 'Diazo - Wedding';
  }
  if (diazoPrenzlauerbergAreas.includes(extractPostalCodeFromAddress(searchString))) {
    return 'Diazo - Prenzlauerberg';
  }
  if (diazoNeukolinAreas.includes(extractPostalCodeFromAddress(searchString))) {
    return 'Diazo - Neukölln';
  }

  if (diazoSchonebergAreas.includes(extractPostalCodeFromAddress(searchString))) {
    return 'Diazo - Schöneberg';
  }
  if (diazoSpandauAreas.includes(extractPostalCodeFromAddress(searchString))) {
    return 'Diazo - Spandau';
  }
  return 'not found';
}
export function findBranchNameByPostalCode(postalCode) {
  if (!postalCode) return '';
  return DiazoOutletInfo.find(branch => branch.postalCodes.includes(postalCode)) || '';
}
export function generateUniqueString() {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ01234567899876543218ABCDEF'.repeat(100);
  let uniqueString = '';
  while (uniqueString.length < 8) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    uniqueString += chars[randomIndex];
  }
  return uniqueString;
}
