export const DiazoOutletInfo = [
  {
    name: 'Diazo-Wedding',
    searchAbleAddress: 'Reinickendorfer Str. 5, 13347 Berlin',
    lat: 52.548636901812095,
    lng: 13.208540196539603,
    postalCodes: [
      '10115',
      '10117',
      '10119',
      '10551',
      '10553',
      '10555',
      '10557',
      '10559',
      '13347',
      '13349',
      '13351',
      '13353',
      '13355',
      '13357',
      '13359',
      '13407',
      '13409',
      '13405',
      '13403',
      '10435',
    ],
  },
  {
    name: 'Diazo-Neukolin',
    searchAbleAddress: 'Silbersteinstraße 36, 12051 Berlin',
    lat: 52.4671824,
    lng: 13.4366736,
    postalCodes: [
      '12051',
      '12049',
      '12055',
      '12043',
      '12057',
      '12099',
      '12457',
      '12099',
      '12457',
      '12359',
      '12109',
      '12059',
      '12045',
      '12047',
      '12435',
      '10997',
      '10999',
      '10967',
      '12437',
      '12107',
      '12349',
      '12351',
      '12353',
      '12357',
      '12487',
      '12439',
      '12459',
      '12107',
      '12277',
      '12305',
      '12249',
      '12307',
      '12309',
      '12279',
      '12355',
      '12489',
      '12053',
      '12347',
    ],
  },
  {
    name: 'Diazo-Spandau',
    searchAbleAddress: 'Kurstraße 22, 13585 Berlin',
    lat: 52.483846655492634,
    lng: 13.361593879135514,
    postalCodes: [
      '13585',
      '13587',
      '13583',
      '13581',
      '13591',
      '13595',
      '13597',
      '13599',
      '13589',
      '13629',
      '13505',
      '14055',
      '14089',
      '14193',
      '14612',
      '14621',
      '14624',
      '16727',
      '16761',
    ],
  },
  {
    name: 'Diazo-Prenzlauerberg',
    searchAbleAddress: 'Greifenhagener Str. 61, 10437 Berlin',
    lat: 52.5460261,
    lng: 13.4142707,
    postalCodes: [
      '10437',
      '10439',
      '13187',
      '13189',
      '10405',
      '10407',
      '13089',
      '10409',
      '10243',
      '13156',
      '13158',
      '10243',
      '10247',
      '10249',
      '13088',
      '13089',
    ],
  },
  {
    name: 'Diazo-Schoneberg',
    searchAbleAddress: 'Leberstraße 25, 10829 Berlin',
    lat: 52.4671824,
    lng: 13.4366736,
    postalCode: [
      '10829',
      '12101',
      '12103',
      '10827',
      '12157',
      '12159',
      '10823',
      '10825',
      '10783',
      '10781',
      '10965',
      '10715',
      '10779',
      '10717',
      '10777',
      '10789',
      '10719',
      '12161',
      '10785',
      '10963',
      '12105',
      '12169',
      '10713',
      '10707',
      '10709',
      '12163',
      '14197',
      '12167',
      '14199',
      '10711',
      '10623',
      '10787',
      '10963',
      '10629',
      '10625',
      '10627',
      '14199',
      '10711',
      '14057',
      '12247',
      '12203',
      '14195',
      '10587',
      '10585',
      '12205',
      '12207',
      '14193',
    ],
  },
];
