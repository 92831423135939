// FavoriteContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getLocalStorage, setLocalStorage } from '../utils/SecuredLocalStorage';

export const FavoriteContext = createContext();

export const FavoriteProvider = ({ children }) => {
  const [favoriteItems, setFavoriteItems] = useState(
    getLocalStorage('favoriteItems')?.length > 0 ? getLocalStorage('favoriteItems') : []
  );

  // Save cart items to localStorage whenever favoriteItems state changes
  useEffect(() => {
    if (favoriteItems.length === 0) {
      localStorage.removeItem('favoriteItems');
    }
    setLocalStorage('favoriteItems', favoriteItems);
  }, [favoriteItems]);

  const addToFavorite = (item, quantity = 1) => {
    // Check if the item already exists in the favorites
    const itemIndex = favoriteItems.findIndex(cartItem => cartItem.name === item.name);

    if (itemIndex !== -1) {
      // If the item exists, remove it from the favorites
      const updatedFavoriteItems = favoriteItems.filter((cartItem, index) => index !== itemIndex);
      setFavoriteItems(updatedFavoriteItems);
    } else {
      // If the item does not exist, add it to the favorites with the specified quantity
      const newItem = { ...item, quantity };
      setFavoriteItems(prevFavoriteItems => [...prevFavoriteItems, newItem]);
    }
  };

  const alreadyFavorited = item => {
    // Check if the item already exists in the favorites
    const itemIndex = favoriteItems.findIndex(cartItem => cartItem.name === item.name);

    if (itemIndex !== -1) {
      return true;
    }
    return false;
  };

  return (
    <FavoriteContext.Provider
      value={{
        favoriteItems,
        addToFavorite,
        alreadyFavorited,
      }}>
      {children}
    </FavoriteContext.Provider>
  );
};
