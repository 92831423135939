import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Iconify from '../iconify/Iconify';

const FooterHeading = ({ text }) => (
  <Typography
    variant="h6"
    sx={{
      color: '#ffffffa3',
      fontWeight: 'bold',
    }}>
    {text}
  </Typography>
);

const SimpleText = ({ onClick, text }) => (
  <Typography
    onClick={onClick}
    variant="body2"
    sx={{
      color: theme => theme.palette.grey[300],
    }}>
    {text}
  </Typography>
);

const IconWithText = ({ onClick, icon, text }) => (
  <Stack direction={'row'} spacing={0.5}>
    <Iconify
      icon={icon}
      sx={{
        color: theme => theme.palette.grey[300],
      }}
    />
    <SimpleText onClick={onClick} text={text} />
  </Stack>
);

const AppStoreButton = ({ icon, downloadOnText, text, onClick }) => (
  <Button
    size="small"
    sx={{
      borderColor: theme => theme.palette.common.white,
      border: '1px solid',
      width: { xs: '130px' },
      bgcolor: theme => theme.palette.primary.main,
      color: theme => theme.palette.common.white,
    }}
    variant="contained"
    startIcon={<Iconify icon={icon} />}
    onClick={onClick}>
    <Box display={'flex'} flexDirection={'column'}>
      <Typography fontSize={8} variant="body2">
        {downloadOnText}
      </Typography>
      <Typography fontSize={12}>{text}</Typography>
    </Box>
  </Button>
);

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{ backgroundColor: theme => theme.palette.common.black }}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      minHeight={220}>
      <Container maxWidth="xl">
        <Grid container className="px-2 py-4 sm:px-4 sm:py-8 md:px-8">
          <Grid item xs={12} sm={6} md={4}>
            <Stack spacing={2} alignItems={'start'}>
              <Typography
                variant="h2"
                noWrap
                onClick={() => navigate('/')}
                sx={{
                  color: theme => theme.palette.common.white,
                  textDecoration: 'none',
                  fontFamily: 'Times New Roman, Times, serif',
                }}>
                DIAZO
              </Typography>
              <Stack className="flex flex-row gap-4 lg:flex-col">
                <AppStoreButton
                  icon={'icomoon-free:appleinc'}
                  downloadOnText={'Download on The'}
                  text={'Apple Store'}
                  onClick={() => window.open('https://apps.apple.com/us/app/diazo/id6448659489', '_blank')}
                />
                <AppStoreButton
                  icon={'logos:google-play-icon'}
                  downloadOnText={'Get it on'}
                  text={'Google Play'}
                  onClick={() => window.open('https://play.google.com/store/apps/details?id=com.diazo.app', '_blank')}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Stack mt={3} alignItems={'center'}>
              <Stack spacing={1}>
                <FooterHeading text={`Folge uns`} />
                <SimpleText text={`Instagram`} />
                <SimpleText text={`Facebook`} />
                <SimpleText text={`Blog`} />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Stack mt={3} alignItems={'center'}>
              <Stack spacing={1}>
                <FooterHeading text={`Nützliche Links`} />
                <SimpleText text={`FAQs & Kundenservice`} onClick={() => navigate('/customersupport')} />
                <SimpleText text={`Impressum Promo-Codes`} />
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={12} lg={12}>
            <Stack
              spacing={1}
              direction={{ lg: 'row', xs: 'column' }}
              mt={5}
              alignItems={'center'}
              textAlign={'left'}
              justifyContent={{ xs: 'justify-start', lg: 'space-between' }}>
              <Box className="flex flex-wrap justify-start gap-4">
                <IconWithText icon={'eva:pin-outline'} text={'Deutschland'} />
                <IconWithText icon={'material-symbols:globe'} text={'Deutsch'} />
              </Box>
              <Box className="flex flex-wrap justify-start gap-4">
                <SimpleText text={`© Diazo ${new Date().getFullYear()}`} />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
