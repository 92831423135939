import { Alert, Snackbar } from '@mui/material';
import React from 'react';

const ErrorBoundry = (WrappedComponent) => {
  const ErrorBoundryComponent = (props) => {
    const [alert, setAlert] = React.useState({ open: false, message: '', type: '' });

    const handleClose = () => {
      setAlert({ open: false, message: '' });
    };

    return (
      <>
        {alert.open && (
          <Snackbar
            open={alert.open}
            autoHideDuration={6000}
            onClose={handleClose}
            // anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            {alert.type === 'success' ? (
              <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                {alert.message}
              </Alert>
            ) : (
              <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                {alert.message}
              </Alert>
            )}
          </Snackbar>
        )}
        <WrappedComponent {...props} handleAlert={setAlert} />
      </>
    );
  };

  return ErrorBoundryComponent;
};

export default ErrorBoundry;
